import React from "react"
import { css } from "@emotion/core"

import Icon from "images/icons/red-tick.svg"

const LIST = [
  "Attack agnostic. Detect zero-days, APTs, insider threats, human and automated attacks",
  "Detect reconnaissance, lateral movement, privilege escalation and data-exfiltration in real-time",
  "No false positives. Every alert is a high-confidence indicator of an attack",
]

const list = css`
  list-style: none;
  margin-left: 0;
  max-width: 520px;
  margin-top: 2.5rem;
  margin-bottom: 0;
  margin-bottom: -20px; // accommodate the last item margin
`

const item = css`
  font-size: 21px;
  line-height: 1.52;
  color: white;
  margin-bottom: 20px;
  opacity: 0.75;
  position: relative;
  display: block;
  padding-left: 2.5rem;
  &::before {
    content: "";
    background-image: url(${Icon});
    background-size: contain;
    background-position: center;
    width: 23px;
    height: 23px;
    position: absolute;
    top: 4px;
    left: 0;
    background-repeat: no-repeat;
  }
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 1.35;
  }
`

function List() {
  return (
    <ul css={list}>
      {LIST.map((itemText, index) => {
        return (
          <li key={index} css={item}>
            {itemText}
          </li>
        )
      })}
    </ul>
  )
}

export default List
