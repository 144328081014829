import { css } from "@emotion/core"
import formCSS from "css/hubspot-form-light"
import { btn } from "css/primitives"
import { colors, radius } from "css/theme"
import { Link } from "gatsby"
import React, { useState } from "react"
import { If } from "src/components"
import { routes } from "src/constants"
import useHubspotForm from "src/hooks/use-hubspot-form"
import { trackEvent } from "src/util"

const wrapper = [
  formCSS,
  css`
    background-color: white;
    border-radius: 8px;
    width: 100%;
    max-width: 526px;
    margin-right: auto;
    padding: 2.5rem;
    padding-bottom: 0.5rem;
    box-shadow: 1px 0 20px 0 black;
    @media (max-width: 1205px) {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      margin-bottom: 2rem;
    }
    @media (max-width: 767px) {
      margin-left: -1rem;
      margin-right: -1rem;
      width: calc(100% + 2rem);
      padding: 1.5rem;
    }

    .hs-form-wrapper {
      min-height: 400px;
    }
  `,
]

const modalButton = [
  btn,
  css`
    background-color: ${colors.orange};
    color: white;
    display: block;
    box-shadow: 1px 0 20px 0 black;
    &:hover {
      color: white;
    }
  `,
]

const modalWrapper = css`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background-color: ${colors.modalWrapperBackground};
`

const modal = css`
  background-color: black;
  border-radius: ${radius.medium};
  color: white;
  max-width: 700px;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  padding: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const modalHeading = css``

const modalDescription = css`
  max-width: 460px;
  font-size: 18px;
  line-height: 1.77;
  opacity: 0.75;
  margin-bottom: 30px;
`

function FormWrapper() {
  const [hasSubmitted, setSubmitted] = useState(false)
  const { error } = useHubspotForm({
    formId: "6300436b-19a4-422e-bb79-6025390b1b90",
    target: "#demo-form",
    onFormSubmitted: () => {
      setSubmitted(true)
      trackEvent("ANY_FORM_SUBMISSION", "Submit")
    },
  })
  return (
    <div css={wrapper}>
      <div id="demo-form" className="hs-form-wrapper">
        {error ? "Could not load form" : null}
      </div>
      <If test={hasSubmitted}>
        <div css={modalWrapper}>
          <div css={modal}>
            <h2 css={modalHeading}>You’re all set!</h2>
            <p css={modalDescription}>
              A deception campaign strategist will reach out to schedule a time
              shortly.
            </p>
            <Link to={routes.home} css={modalButton}>
              Continue Browsing
            </Link>
          </div>
        </div>
      </If>
    </div>
  )
}

export default FormWrapper
