import React from "react"

import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"
import ogImage from "images/seo/demo.jpeg"

import Demo from "../views/demo"

const DemoPage = () => (
  <div>
    <SEO
      title="Get a Demo"
      description="Get a complete walkthrough of Smokescreen's award-winning deception platform and learn how it can help you detect targeted threats. Schedule now."
      image={ogImage}
    />
    <PageLayout options={{ headingBackground: false }}>
      <Demo />
    </PageLayout>
  </div>
)

export default DemoPage
