import React from "react"
import { css } from "@emotion/core"

import { h2 } from "css/primitives"

const wrapper = css`
  color: white;
  display: flex;
  flex-direction: column;
`

const heading = [
  h2,
  css`
    font-size: 28px;
    line-height: 2;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 1.5;
    }
  `,
]
const description = css`
  font-size: 18px;
  line-height: 1.77;
  opacity: 0.5;
  @media (max-width: 479px) {
    font-size: 1rem;
    line-height: 1.3;
  }
`

function Header() {
  return (
    <div css={wrapper}>
      <h1 css={heading}>Request a live demo</h1>
      <p css={description}>See how deception works in 60 minutes</p>
    </div>
  )
}

export default Header
