import React from "react"
import { css } from "@emotion/core"

import Image from "images/homepage/hero-image-1x.png"

const image = css`
  max-width: 550px;
`

function HeroImage() {
  return (
    <div css={image}>
      <img
        src={Image}
        alt="Smokescreen Investigate Page Dashboard with detailed attacker view open"
      />
    </div>
  )
}

export default HeroImage
