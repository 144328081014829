import React from "react"
import { css } from "@emotion/core"

import { colors } from "css/theme"

import { Header, Form, HeroImage, List } from "./components"

const background = css`
  background-color: ${colors.darkBackground};
  min-height: 100vh;
  padding-bottom: 100px;
  @media (max-width: 479px) {
    padding-bottom: 75px;
  }
`
const top = css`
  background-color: black;
  background-image: radial-gradient(
    ellipse at top,
    ${colors.gradientStartColor} 0%,
    black 50%
  );
  padding-top: 130px;
  padding-bottom: 250px;
  margin-top: -90px;
`

const wrapperInner = css`
  display: flex;
  max-width: 1240px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 991px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`

const flexLeft = css`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: -250px;
  padding-right: 1rem;
  @media (max-width: 991px) {
    display: none;
  }
`
const flexRight = css`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: -250px;
  @media (max-width: 991px) {
    width: 100%;
    max-width: 520px;
  }
`

function Demo() {
  return (
    <div css={background}>
      <div css={top} />
      <div css={wrapperInner}>
        <div css={flexLeft}>
          <HeroImage />
          <List />
        </div>
        <div css={flexRight}>
          <Header />
          <Form />
        </div>
      </div>
    </div>
  )
}

export default Demo
